

// For display only - won't work with forms


import React from "react";
import { motion } from "framer-motion";
import {colors} from "../colors";
import Typography from "../typography";
import {breakpoints} from "../tokens";

const tickVariants = {
    checked: {
        pathLength: 1,
        opacity: 1,
        transition: {
            duration: 0.2,
        },
    },
    unchecked: {
        pathLength: 0,
        opacity: 0,
        transition: {
            duration: 0.2,
        },
    },
};

const AnimatedCheckbox = ({ checked, disabled, setChecked, children, theme = "blue", size = "lg" }: React.PropsWithChildren<{ disabled?: boolean, checked: boolean, size?: "sm"|"lg", setChecked: React.Dispatch<React.SetStateAction<boolean>>, theme?: "blue" | "orange" }>) => {
    const sizes = {
        lg: {
            width: 20,
            height: 20
        },
        sm: {
            width: 14,
            height: 14
        }
    }[size]

    return (
        <button disabled={disabled} type="button" css={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: size === "lg" ? '20px' : '8px',
            transition: 'opacity 0.2s',
            '&:disabled': {
                opacity: 0.5,
                cursor: 'not-allowed'
            },
        }} onClick={(e) => {
            e.stopPropagation()
            setChecked(!checked);
        }}>
            <div css={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center'
            }}>
                <motion.div
                    css={{
                        width: sizes.width,
                        height: sizes.height,
                        borderRadius: '1.5px'
                    }}
                    variants={{
                        checked: {
                            border: `1px solid ${theme === "orange" ? colors.primaryOrange :colors.tertiaryBlue5}`,
                            backgroundColor: theme === "orange" ? colors.primaryOrange : "transparent",
                            transition: {
                                duration: 0.2,
                            },
                        },
                        unchecked: {
                            border: `1px solid ${theme === "orange" ? colors.neutral7 :colors.tertiaryBlue5}`,
                            backgroundColor: theme === "orange" ? "#ff500000" : "transparent",
                            transition: {
                                duration: 0.2,
                            },
                        },
                    }}
                    initial={false}
                    animate={checked ? "checked" : "unchecked"}
                />
                <div css={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-40%, -55%)',
                }}>
                    <motion.svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke={theme === "orange" ? colors.shadesWhite : colors.tertiaryBlue5}
                        css={{
                            width: sizes.width + 2,
                            height: sizes.height + 2,
                        }}
                        variants={tickVariants}
                        initial={false}
                        animate={checked ? "checked" : "unchecked"}
                    >
                        <motion.path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                            variants={tickVariants}
                        />
                    </motion.svg>
                </div>
            </div>
            <Typography fontSize={{
                dt: theme === "orange" ? "p1" : 'c1'
            }} css={{
                marginTop: '-2px'
            }} color={theme === "orange" ? "neutral10" : "tertiaryBlue5"}>
                {children}
            </Typography>
        </button>
    )
}

export default AnimatedCheckbox;
